@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz@8..144&display=swap');
@layer base {
    body {
        @apply font-[Roboto];
    }
    li 
    {
        @apply px-4;
        @apply cursor-pointer;
    }
}